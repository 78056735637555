/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  titleVECTOR: {
    id: "publications.title.vector",
    defaultMessage: "Datalagen",
  },
  publicationsModifyDatagroup: {
    id: "publications.modify.datagroup",
    defaultMessage: "Bewerk datagroep",
  },
  publicationsModifyBaselayers: {
    id: "publications.modify.baselayers",
    defaultMessage: "Bewerk basiskaarten",
  },
  publicationsModifyImages: {
    id: "publications.modify.images",
    defaultMessage: "Bewerk afbeeldingen",
  },
  publicationsModifyTitlePublication: {
    id: "publications.modify.title.publication",
    defaultMessage: "Bewerk titel publicatie",
  },
  publicationsModifySearch: {
    id: "publications.modify.search",
    defaultMessage: "Bewerk zoek",
  },
  publicationsChooseConfiguration: {
    id: "publications.choose.configuration",
    defaultMessage: "Kies een analyseconfiguratie",
  },
  publicationsChooseExtraEnrichments: {
    id: "publications.choose.extra.enrichments",
    defaultMessage: "Kies verrijkingen",
  },
  publicationsChooseDefaultZoomTitle: {
    id: "publications.choose.default.zoom.title",
    defaultMessage: "Standaard zoom",
  },
  publicationsTodoLinkType: {
    id: "publications.todo.link.type",
    defaultMessage: "TODO: Link this type:",
  },
  publicationsTodoLinkToSave: {
    id: "publications.todo.link.to.save",
    defaultMessage: "TODO: Link this type to save function:",
  },
  publicationsDetailsPublication: {
    id: "publications.details.publication",
    defaultMessage: "Details publicatie:",
  },
  publicationsCreatedOn: {
    id: "publications.created.on",
    defaultMessage: "Aangemaakt op",
  },
  publicationsModifiedOn: {
    id: "publications.modified.on",
    defaultMessage: ", bewerkt op",
  },
  publicationsSearchable: {
    id: "publications.searchable",
    defaultMessage: "zoekbaar",
  },
  publicationsMakeSearchable: {
    id: "publications.make.searchable",
    defaultMessage: "Maak laag zoekbaar",
  },
  publicationsMakeEnrichmentSearchable: {
    id: "publications.make.enrichment.searchable",
    defaultMessage: "Maak index zoekbaar",
  },
  publicationsFuzzy: {
    id: "publications.fuzzy",
    defaultMessage: "Fuzzy",
  },
  publicationsUseFuzzySearch: {
    id: "publications.use.fuzzy.search",
    defaultMessage: "Fuzzy search",
  },
  publicationsPublicationURLs: {
    id: "publications.publication.urls",
    defaultMessage: "Publicatie URL's",
  },
  publicationsPublicationShareConfig: {
    id: "publications.publication.share.config",
    defaultMessage: "Toegangsbeheer",
  },
  publicationsPublicationShareConfigPublicCheckbox: {
    id: "publications.publication.share.config.public.checkbox",
    defaultMessage: "Publiek beschikbaar",
  },
  publicationsPublicationShareConfigPublicWarning: {
    id: "publications.publication.share.config.public.warning",
    defaultMessage: "Iedereen op internet met de link kan hiermee deze publicatie (met alle onderliggende lagen) bekijken en bevragen.",
  },
  publicationsViewer: {
    id: "publications.viewer",
    defaultMessage: "Viewer",
  },
  publicationsCopyURL: {
    id: "publications.copy.url",
    defaultMessage: "Kopiëer URL",
  },
  publicationsOpenURL: {
    id: "publications.open.url",
    defaultMessage: "Open URL",
  },
  publicationsViewerNew: {
    id: "publications.viewer.new",
    defaultMessage: "Studio Viewer",
  },
  publicationsKey: {
    id: "publications.key",
    defaultMessage: "Key",
  },
  publicationsCopyKey: {
    id: "publications.copy.key",
    defaultMessage: "Kopiëer key",
  },
  publicationsDefaultVisibility: {
    id: "publications.default.visibility",
    defaultMessage: "Zichtbaarheid lagen",
  },
  publicationsDefaultVisibilityTitle: {
    id: "publications.default.visibility.title",
    defaultMessage: "Bepaal de standaard zichtbaarheid van de lagen",
  },
  publicationsDefaultVisibilityDescription: {
    id: "publications.default.visibility.description",
    defaultMessage: "Toon/verberg kaartlagen bij opstart van de publicatie",
  },
  publicationsDefaultVisibilityDatalayers: {
    id: "publications.default.visibility.datalayers",
    defaultMessage: "Datalagen",
  },
  publicationsDefaultVisibilityTransferlayers: {
    id: "publications.default.visibility.transferlayers",
    defaultMessage: "WFS / WMS",
  },
  publicationsDefaultVisibilityImages: {
    id: "publications.default.visibility.images",
    defaultMessage: "Afbeeldingen",
  },
  publicationsDefaultVisibilityDatalayersNotFound: {
    id: "publications.default.visibility.datalayers.notfound",
    defaultMessage: "Er zijn geen datalagen in deze publicatie.",
  },
  publicationsDefaultVisibilityTransferlayersNotFound: {
    id: "publications.default.visibility.transferlayers.notfound",
    defaultMessage: "Er zijn geen WFS/WMS lagen in deze publicatie.",
  },
  publicationsDefaultVisibilityImagesNotFound: {
    id: "publications.default.visibility.images.notfound",
    defaultMessage: "Er zijn geen afbeeldingen in deze publicatie.",
  },
  publicationsModify: {
    id: "publications.modify",
    defaultMessage: "Bewerken",
  },
  publicationsDatagroup: {
    id: "publications.datagroup",
    defaultMessage: "Datagroep",
  },
  publicationsSynchronizePublication: {
    id: "publications.synchronize.publication",
    defaultMessage: "Synchroniseer de publicatie met de stijl van de datagroep",
  },
  publicationsNoLinkedDatagroup: {
    id: "publications.no.linked.datagroup",
    defaultMessage: "Geen datagroep gekoppeld.",
  },
  publicationsSearchConfiguration: {
    id: "publications.search.configuration",
    defaultMessage: "Zoeken configuratie",
  },
  publicationsPrimaryField: {
    id: "publications.layer.primary.field",
    defaultMessage: "Primair veld",
  },
  publicationsNoPrimary: {
    id: "publications.layer.primary.no",
    defaultMessage: "Geen primair veld",
  },
  publicationsCopyConfigurationJson: {
    id: "publications.copy.configuration.json",
    defaultMessage: "Kopieer configuratie als JSON",
  },
  publicationsImages: {
    id: "publications.images",
    defaultMessage: "Afbeeldingen",
  },
  publicationsNoLinkedImages: {
    id: "publications.no.linked.images",
    defaultMessage: "Er zijn geen afbeeldingen in deze publicatie.",
  },
  publicationsBaselayers: {
    id: "publications.baselayers",
    defaultMessage: "Basiskaarten",
  },
  publicationsNoLinkedBaselayers: {
    id: "publications.no.linked.baselayers",
    defaultMessage: "Geen basiskaarten gekoppeld.",
  },
  publicationsChooseWMSWFS: {
    id: "publications.choose.wms.wfs",
    defaultMessage: "Kies WMS/WFS",
  },
  publicationsNoLinkedWMSWFS: {
    id: "publications.no.linked.wms.wfs",
    defaultMessage: "Er zijn geen WFS/WMS lagen in deze publicatie.",
  },
  publicationsNoDefaultZoom: {
    id: "publications.no.default.zoom",
    defaultMessage: "Geen standaard zoom ingesteld.",
  },
  publicationsDefineOrderLayers: {
    id: "publications.define.order.layers",
    defaultMessage: "Bepaal volgorde van de lagen",
  },
  publicationsOrderVisibilityTitle: {
    id: "publications.order.visibility.title",
    defaultMessage: "Lagenstructuur",
  },
  publicationsOrderVisibilityWarning: {
    id: "publications.order.visibility.warning",
    defaultMessage: "Je kunt de volgorde en de zichtbaarheid van de lagen aanpassen in Studio Viewer.",
  },
  publicationsOldOrderWarning: {
    id: "publications.old.order.warning",
    defaultMessage: "De huidige laagvolgorde volgt een oude structuur. Gebruik Studio Viewer om de nieuwe structuur toe te passen.",
  },
  publicationsGotoViewer: {
    id: "publications.goto.viewer",
    defaultMessage: "Ga naar Studio Viewer",
  },
  publicationsChooseDrawTools: {
    id: "publications.choose.draw.tools",
    defaultMessage: "Tekentools",
  },
  publicationsDrawToolsHeader: {
    id: "publications.choose.draw.tools.header",
    defaultMessage: "Bewerk tekentools",
  },
  publicationsChooseDefaultZoom: {
    id: "publications.choose.default.zoom",
    defaultMessage: "Standaard zoom",
  },
  publicationsESIndices: {
    id: "publications.es.indices",
    defaultMessage: "Elasticsearch indices",
  },
  publicationsESData: {
    id: "publications.es.data",
    defaultMessage: "Elasticsearch data",
  },
  publicationsNoLinkedEnrichment: {
    id: "publications.no.linked.enrichment",
    defaultMessage: "Geen verrijking gekoppeld.",
  },
  publicationsAnalyse: {
    id: "publications.analyse",
    defaultMessage: "Analyse",
  },
  publicationsNoLinkedAnalyse: {
    id: "publications.no.linked.analyse",
    defaultMessage: "Geen analyse gekoppeld.",
  },
  publicationsNoDatagroup: {
    id: "publications.no.datagroup",
    defaultMessage: "Geen datagroep",
  },
  publicationsChooseImages: {
    id: "publications.choose.images",
    defaultMessage: "Kies afbeelding(en) (max nog te bepalen)",
  },
  publicationsNoCurrentImages: {
    id: "publications.no.current.images",
    defaultMessage: "Er zijn momenteel geen afbeeldingen om uit te kiezen",
  },
  publicationsChooseBaselayers: {
    id: "publications.choose.baselayers",
    defaultMessage: "Kies basiskaart(en) (max nog te bepalen)",
  },
  publicationsChooseDefaultZoomLabel: {
    id: "publications.choose.default.zoom.label",
    defaultMessage: "Kies datalagen voor de standaard zoom",
  },
  publicationsChooseDefaultZoomOption1: {
    id: "publications.choose.default.zoom.option.1",
    defaultMessage: "Publicatie datalagen",
  },
  publicationsChooseDefaultZoomOption2: {
    id: "publications.choose.default.zoom.option.2",
    defaultMessage: "Algemene datalagen",
  },
  publicationsChooseDefaultZoomOption3: {
    id: "publications.choose.default.zoom.option.3",
    defaultMessage: "Zelf BBOX ingeven",
  },
  publicationsChooseDefaultZoomOption3Label: {
    id: "publications.choose.default.zoom.option.3.label",
    defaultMessage: "Vul zelf je bounding box in:",
  },
  publicationsChooseDefaultZoomOption3Error: {
    id: "publications.choose.default.zoom.option.3.error",
    defaultMessage: "Gelieve een geldige bounding box in te geven. Bijvoorbeeld: 51.12272556931933, 4.206070986306997, 51.12904013319343, 4.222534871828856",
  },
  publicationsNoCurrentBaselayers: {
    id: "publications.no.current.baselayers",
    defaultMessage: "Er zijn momenteel geen basiskaarten om uit te kiezen",
  },
  publicationsNoLayerGroupSelected: {
    id: "publications.no.current.layergroup",
    defaultMessage: "Er zijn momenteel geen kaartlagen om uit te kiezen.",
  },
  publicationsSearchName: {
    id: "publications.search.name",
    defaultMessage: "Zoeken op naam",
  },
  publicationsSearch: {
    id: "publications.search",
    defaultMessage: "search",
  },
  publicationsSearchType: {
    id: "publications.search.type",
    defaultMessage: "Zoeken op type",
  },
  publicationsWMSWFS: {
    id: "publications.wms.wfs",
    defaultMessage: "WMS en WFS",
  },
  publicationsWMSWFSAll: {
    id: "publications.wms.wfs.all",
    defaultMessage: "Alle",
  },
  publicationsOnlyShared: {
    id: "publications.only.shared",
    defaultMessage: "Enkel gedeeld",
  },
  publicationsOnlyWMS: {
    id: "publications.only.wms",
    defaultMessage: "Enkel WMS",
  },
  publicationsOnlyWFS: {
    id: "publications.only.wfs",
    defaultMessage: "Enkel WFS",
  },
  publicationsType: {
    id: "publications.type",
    defaultMessage: "Type",
  },
  publicationsName: {
    id: "publications.name",
    defaultMessage: "Naam",
  },
  nameError: {
    id: "name.error",
    defaultMessage: "Alleen letters, cijfers, spaties, -, en _ zijn toegestaan.",
  },
  publicationsFilterEnrichmentType: {
    id: "publications.filter.enrichment.type",
    defaultMessage: "Filter op verrijkingstype",
  },
  publicationsAll: {
    id: "publications.all",
    defaultMessage: "All",
  },
  publicationsSearchEnglish: {
    id: "publications.search.english",
    defaultMessage: "Search",
  },
  publicationsDefault: {
    id: "publications.default",
    defaultMessage: "Default",
  },
  publicationsChooseEnrichments: {
    id: "publications.choose.enrichments",
    defaultMessage: "Kies verrijking(en)",
  },
  publicationsNoEnrichments: {
    id: "publications.no.enrichments",
    defaultMessage: "Geen verrijkingen beschikbaar voor het geselecteerde type",
  },
  publicationsNoCurrentEnrichments: {
    id: "publications.no.current.enrichments",
    defaultMessage: "Er zijn momenteel geen verrijkingen om uit te kiezen",
  },
  publicationsNameAnalyseConfig: {
    id: "publications.name.analyse.config",
    defaultMessage: "Naam van de analyseconfiguratie",
  },
  publicationsNone: {
    id: "publications.none",
    defaultMessage: "None",
  },
  publicationsNoAnalyse: {
    id: "publications.no.analyse",
    defaultMessage: "Geen analyse",
  },
  publicationsNoCurrentAnalyse: {
    id: "publications.no.current.analyse",
    defaultMessage: "Er zijn momenteel geen analyse configs om uit te kiezen",
  },
  publicationsCancel: {
    id: "publications.cancel",
    defaultMessage: "Annuleren",
  },
  publicationsSave: {
    id: "publications.save",
    defaultMessage: "Opslaan",
  },
  publicationsReset: {
    id: "publications.reset",
    defaultMessage: "Standaard waarde herstellen",
  },
  publicationsOrbitViewer: {
    id: "publications.orbit.viewer",
    defaultMessage: "Studio Manager",
  },
  publicationsEnterPublicationCode: {
    id: "publications.enter.publication.code",
    defaultMessage: "Voer een publicatie code in",
  },
  publicationsSend: {
    id: "publications.send",
    defaultMessage: "Verzenden",
  },
  publicationsNew: {
    id: "publications.new",
    defaultMessage: "Nieuw",
  },
  publicationsPublications: {
    id: "publications.publications",
    defaultMessage: "Publicaties",
  },
  publicationsPublication: {
    id: "publications.publication",
    defaultMessage: "Publicatie",
  },
  publicationsPublicationExplanation: {
    id: "publications.publication.explanation",
    defaultMessage: "Wat is een publicatie?",
  },
  publicationsPublicationExplanationInfo: {
    id: "publications.publication.explanation.info",
    defaultMessage:
      "Een publicatie is een viewer die publiek toegankelijk zal zijn. Je zal hiervoor een URL ontvangen die je dan kan gebruiken om als view in te laden in je eigen applicatie.",
  },
  publicationsNewPublication: {
    id: "publications.new.publication",
    defaultMessage: "Nieuwe publicatie",
  },
  publicationsPublicationName: {
    id: "publications.publication.name",
    defaultMessage: "Publicatienaam",
  },
  publicationsCreate: {
    id: "publications.create",
    defaultMessage: "Aanmaken",
  },
  publicationsChooseDatagroup: {
    id: "publications.choose.datagroup",
    defaultMessage: "Kies datagroep (max 1)",
  },
});
