import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PaletteIcon from "@material-ui/icons/Palette";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import LinkIcon from "@mui/icons-material/Link";
import { FileTypes, LayerGroupStatus } from "@orbit/geo-core-shared";
import clsx from "clsx";
import { StoresContext } from "index";
import { observer as hooksObserver } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { ROUTE_DATAGROUPS, ROUTE_PUBLICATIONS } from "routes/RouteList";
import { publishLayerGroup, updateLayergroupName } from "services/geo-core";
import { DataLayerType } from "stores/DataLayerStore";
import { useClipboard } from "use-clipboard-copy";
import { getItemMetaLabel } from "utils/string";
import CustomDialogView from "views/CustomDialog/CustomDialogView";
import BaseMap from "views/Map/BaseMap";
import { BaseMapActionTypes, useBaseMap } from "views/Map/BaseMapContext";
import SimpleTable from "views/Table/SimpleTable";
import { getLayerGroupStatusLabel } from "../../../constants";
import { ROUTE_DATALAYERS } from "../../../routes/RouteList";
import { firstLetterUpperCase } from "../../../utils";
import EditDialog from "../datalayer/EditTitleDialog";
import useDatagroupDetailStyles from "./DatagroupDetailStyles";
import messages from "./messages";

const DatagroupDetail: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = hooksObserver(({ classes, intl, intl: { formatMessage } }) => {
  const {
    dataLayerStore: { layers, loadData: loadLayers },
    dataGroupStore: { layergroups, getActiveLayergroup, clearActiveLayergroup, activeLayergroup, baseLayers, getLayerGroupActiveStyle },
    mapStore: { latLng },
    authStore: { token },
    publicationStore: { publications, loadPublications },
  } = useContext(StoresContext);
  let { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const clipboard = useClipboard();

  const { state, dispatch } = useBaseMap();
  const [config, setConfig] = useState<{ overwriteStyle: boolean; layerIds?: string[] | null }>({ overwriteStyle: false, layerIds: null });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedLayers, setSelectedLayers] = useState<string[]>([]);

  const updateConfig = (key, value) => setConfig({ ...config, [key]: value });

  useEffect(() => {
    loadLayers(DataLayerType.VECTOR);
    return () => dispatch({ type: BaseMapActionTypes.RESET });
  }, []);
  useEffect(() => {
    loadPublications();
  }, []);
  useEffect(() => {
    if (!id) {
      return;
    }
    getActiveLayergroup(id);
    return () => {
      clearActiveLayergroup();
    };
  }, [layergroups, id]);

  useEffect(() => {
    const notUpdated =
      selectedLayers.length === activeLayergroup?.layers?.length && selectedLayers.length > 0 && !activeLayergroup?.layers?.some((l) => !selectedLayers.find((id) => l.id === id));
    updateConfig("layerIds", !notUpdated ? selectedLayers : null);
  }, [selectedLayers]);

  const changeLayerName = async (newName) => {
    await updateLayergroupName(id, newName);
    getActiveLayergroup(id);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedLayers([]);
  };

  const toggleSelectedLayers = (layerId) => {
    const newSelectedLayers = selectedLayers.indexOf(layerId) !== -1 ? selectedLayers.filter((value) => value !== layerId) : selectedLayers.concat([layerId]);

    setSelectedLayers(newSelectedLayers);
  };

  const sortedLayers =
    layers?.slice().sort((a, b) => (activeLayergroup?.layers.findIndex((l) => l.id === b.id) ?? -1) - (activeLayergroup?.layers.findIndex((l) => l.id === a.id) ?? -1)) || [];

  const relatedPublications = publications.filter((publication) => activeLayergroup?.id === publication.layerGroup?.id);
  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.datagroupsDetails)} {activeLayergroup?.name}
            <EditDialog
              key={activeLayergroup?.name}
              label={formatMessage(messages.datagroupsNameGroup)}
              defaultValue={activeLayergroup?.name}
              onSave={(newName) => changeLayerName(newName)}
              disabled={activeLayergroup?.status !== LayerGroupStatus.FINISHED && activeLayergroup?.status !== LayerGroupStatus.ERROR}
              intl={formatMessage}
              title={formatMessage(messages.datagroupsGroupNameUpdateTitle)}
              allowedCharacters={/^[a-zA-Z0-9\s\-_]*$/}
              errorText={formatMessage(messages.nameError)}
            />
            <IconButton disabled={activeLayergroup?.status !== LayerGroupStatus.FINISHED} onClick={() => push(ROUTE_DATAGROUPS + "/style/" + id)}>
              <PaletteIcon />
            </IconButton>
          </Typography>
          {activeLayergroup && activeLayergroup?.status !== LayerGroupStatus.ERROR && activeLayergroup?.status !== LayerGroupStatus.FINISHED && (
            <Alert severity="warning">
              <Tooltip title={getLayerGroupStatusLabel(activeLayergroup?.status)}>
                <span>{formatMessage(messages.datagroupsStillProcessingDatagroup)}</span>
              </Tooltip>
            </Alert>
          )}
          {activeLayergroup && activeLayergroup?.status === LayerGroupStatus.ERROR && <Alert severity="error">{formatMessage(messages.datagroupsWrong)}</Alert>}

          <Typography variant="h6" gutterBottom>
            {getItemMetaLabel({ intl, item: activeLayergroup })}
          </Typography>

          <div style={{ width: "100%", height: 500 }}>
            <BaseMap latLng={latLng} baseLayers={baseLayers} mapStyle={getLayerGroupActiveStyle()} bounds={activeLayergroup?.bbox} sortedLayers={[]} />
          </div>
        </Grid>
        <br />
        <Grid item xs={4}>
          <Grid container direction="column" style={{ marginTop: 20 }}>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.datagroupsPublications)}
            </Typography>
            {relatedPublications.length ? (
              relatedPublications.map((publication) => (
                <div
                  key={publication.id}
                  style={{ display: "flex", alignItems: "center", marginBottom: "4px", paddingLeft: "8px" }}
                  onMouseEnter={(e) => ((e.target as HTMLElement).style.opacity = "0.8")}
                  onMouseLeave={(e) => ((e.target as HTMLElement).style.opacity = "1")}
                >
                  <LinkIcon fontSize="small" />
                  <div
                    style={{ cursor: "pointer", paddingLeft: "8px" }}
                    onClick={() => {
                      push(`${ROUTE_PUBLICATIONS}/${publication.id}`);
                    }}
                  >
                    {publication.name}
                  </div>
                </div>
              ))
            ) : (
              <div>{formatMessage(messages.datagroupsNoLinkedPublications)}</div>
            )}
          </Grid>
          <Typography style={{ marginTop: 20 }} variant="h5" gutterBottom>
            {formatMessage(messages.datagroupsPublicURLs)}
          </Typography>
          <Grid container direction="column" spacing={2}>
            {activeLayergroup?.mapServices.map((mapService) => (
              <Grid key={mapService.url} item>
                <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                  <InputLabel htmlFor="standard-adornment-password">
                    {firstLetterUpperCase(mapService.type)} {formatMessage(messages.datagroupsURL)}
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type="text"
                    fullWidth
                    value={mapService.url}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label={`${formatMessage(messages.datagroupsCopy)} ${mapService.type}`} onClick={() => clipboard.copy(mapService.url)}>
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
          <Grid>
            <Grid container direction="column" style={{ marginTop: 20 }}>
              <Button
                id="submit"
                color="primary"
                variant="contained"
                disabled={activeLayergroup?.status !== LayerGroupStatus.FINISHED && activeLayergroup?.status !== LayerGroupStatus.ERROR}
                onClick={() => {
                  setSelectedLayers(activeLayergroup?.layers.map((layer) => layer.id) || []);
                  updateConfig("layerIds", null);
                  setDialogOpen(true);
                }}
                style={{ marginTop: 3 }}
                disableElevation
              >
                {formatMessage(messages.datagroupsPublishAgain)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.chipsList}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.titleVECTOR)}
            </Typography>
            <Button
              id="submit"
              size="small"
              variant="outlined"
              disabled={activeLayergroup?.status !== LayerGroupStatus.FINISHED && activeLayergroup?.status !== LayerGroupStatus.ERROR}
              onClick={() => {
                setSelectedLayers(activeLayergroup?.layers.map((layer) => layer.id) || []);
                updateConfig("layerIds", null);
                setDialogOpen(true);
              }}
              style={{ marginLeft: 5, marginTop: 3, height: 30, width: 5 }}
              disableElevation
            >
              +
            </Button>
          </Grid>
          <div>
            {activeLayergroup?.layers.map((layer) => (
              <Chip
                clickable
                onClick={() => push(ROUTE_DATALAYERS + "/" + layer.id)}
                key={layer.layerName}
                label={layer.displayName}
                className={classes.chips}
                color="primary"
                variant="outlined"
              />
            ))}
          </div>
        </div>
        {state?.relationalItems?.length > 0 && <SimpleTable data={state.relationalItems} action={null} />}
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={closeDialog}
        dialogTitle={formatMessage(messages.datagroupsPublishAgain)}
        dialogContent={
          <Fragment>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={activeLayergroup?.status !== LayerGroupStatus.FINISHED && activeLayergroup?.status !== LayerGroupStatus.ERROR}
                  checked={config.overwriteStyle}
                  onChange={(event) => updateConfig("overwriteStyle", event.target.checked)}
                  name="overwriteStyle"
                />
              }
              label={formatMessage(messages.datagroupsOverwriteStyle)}
              style={{ marginBottom: 10 }}
            />
            <Typography>{formatMessage(messages.datagroupsUseDatalayers)}</Typography>
            <List className={classes.modalList}>
              {sortedLayers
                .filter((layer) => layer.status === "finished")
                .filter((layer) => layer.fileType !== FileTypes.CSV)
                .map((layer) => {
                  return (
                    <ListItem key={layer.id} role={undefined} dense button onClick={() => toggleSelectedLayers(layer.id)}>
                      <ListItemIcon>
                        <Checkbox edge="start" checked={selectedLayers.indexOf(layer.id) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": layer.id }} />
                      </ListItemIcon>
                      <ListItemText id={layer.id} primary={layer.displayName} />
                    </ListItem>
                  );
                })}
            </List>
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button id="submit" onClick={closeDialog}>
              {formatMessage(messages.datagroupsCancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant="contained"
              onClick={async () => {
                closeDialog();
                await publishLayerGroup(id, config);
                getActiveLayergroup(id);
              }}
              disableElevation
              disabled={(activeLayergroup?.status !== LayerGroupStatus.FINISHED && activeLayergroup?.status !== LayerGroupStatus.ERROR) || !selectedLayers?.length}
            >
              {formatMessage(messages.datagroupsPublish)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useDatagroupDetailStyles)(injectIntl(DatagroupDetail));
