import { withStyles } from "@material-ui/styles";
import { createLayerId, WfsTransferLayerListObject } from "@orbit/geo-core-shared";
import { getFlatSortedLayers } from "containers/partials/publication/layers/SortLayers";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { Layer, MapContext, Source } from "react-map-gl";
import { fetchWfsFeatures } from "services/transfer";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { baseLayerModel } from "views/Map/BaseMap";
import useMapStyles from "./MapStyles";

const getDefaultDataForWfsLayer = (wfsLayer: WfsTransferLayerListObject) => {
  const layers = wfsLayer?.featureTypes?.reduce((accum, fType) => {
    const name = fType?.featureType?.Name;
    if (!accum[name]) {
      accum[name] = getLayerProperties(name, wfsLayer);
    }
    return accum;
  }, {});

  return {
    geojson: {
      type: "FeatureCollection",
      features: [],
    },
    layers: layers,
    id: wfsLayer.id,
  };
};
const getLayerProperties = (
  name: string,
  wfsLayer: WfsTransferLayerListObject,
): {
  id: string;
  type: string;
  paint: mapboxgl.AnyPaint;
  filter: string[];
} => {
  return {
    id: name,
    type: getLayerType(name, wfsLayer),
    paint: getLayerPaint(name, wfsLayer),
    filter: ["==", "wfsType", name],
  };
};
const WfsLayerView: FunctionComponent<{
  wfsLayers: WfsTransferLayerListObject[];
  viewPort: any;
  getBeforeId: (id: string) => string;
}> = observer(({ wfsLayers, viewPort = () => {}, getBeforeId }) => {
  const [allData, setAllData] = useState<any>(null);
  const { map } = React.useContext(MapContext);
  const [viewPortDebounced] = useDebounce(viewPort, 250);

  const { setLoading } = baseLayerModel;

  const layerNames = wfsLayers.map((layer) => layer.featureTypes.map(({ featureType: { Name } }) => Name));

  /**
   *  -> wfs laag -> leeg object
   *  -> features
   *    wfs -> geojson <Source>
   *          gvp-> filter wfs geojson whre gvp <Layer>
   *          gvl-> filter wfs geojson whre gvl <Layer>
   *
   */
  const loadWfsFeatures = async (wfsLayer: WfsTransferLayerListObject, index) => {
    // map.removeLayer()
    const zoom = map.getZoom();
    if (zoom < wfsLayer.zoomlevel) {
      return getDefaultDataForWfsLayer(wfsLayer);
    }
    const { _ne, _sw } = map.getBounds();
    const bbox = [_sw.lng, _sw.lat, _ne.lng, _ne.lat];
    const data = await fetchWfsFeatures(wfsLayer, bbox);

    if (data?.length > 0) {
      const layers = {};
      const geojson = {
        type: "FeatureCollection",
        features: data.map((feature) => {
          const layerRegex = feature.id
            ? new RegExp(feature.id.toString().toLowerCase().split(".")[0])
            : new RegExp(feature.properties.GmlID.toString().toLowerCase().split(".")[0]);
          const name = layerNames[index].filter((layerName) => layerName.toString().toLowerCase().match(layerRegex))[0];
          if (!layers[name]) {
            layers[name] = getLayerProperties(name, wfsLayer);
          }
          const wfsType = name;
          return { ...feature, properties: { ...feature.properties, wfsType } };
        }),
      };
      return { geojson, layers, id: wfsLayer.id };
    }
    return getDefaultDataForWfsLayer(wfsLayer);
  };

  const loadWfsFeaturesDebounced = useDebouncedCallback(
    // function
    async () => {
      const data = await loadAllWfsFeatures();
      return data;
    },
    // delay in ms
    500,
    { leading: false },
  );

  async function loadAllWfsFeatures() {
    setLoading(true);
    const result = await Promise.all(wfsLayers.map(async (wfsLayer, i) => await loadWfsFeatures(wfsLayer, i)));
    setLoading(false);
    const [hasData] = result;
    if (hasData !== undefined) {
      setAllData(result);
    }
  }

  useEffect(() => {
    loadWfsFeaturesDebounced();
  }, [viewPortDebounced]);

  return (
    allData &&
    allData?.map?.((data) => {
      if (!data) {
        return null;
      }
      return <WFSSource key={data} data={data} wfsLayers={wfsLayers} getBeforeId={getBeforeId} />;
    })
  );
});

interface WfsSourceData {
  id: string;
  geojson: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | string;
  layers: any[];
}

const WFSSource = observer(({ data, wfsLayers, getBeforeId }: { data: WfsSourceData; wfsLayers; getBeforeId: (id: string) => string }) => {
  const { id, geojson, layers } = data;
  // check if the current layer is enabled
  //if not we don't need to continue rendering
  const currentLayer = useMemo(() => wfsLayers.find((layer) => id === layer.id), [wfsLayers, id]);

  return (
    <>
      <Source key={id} id={id} type="geojson" data={geojson}>
        {Object.values(layers).map?.((layer: any) => (
          <WFSLayer key={layer.id} currentLayer={currentLayer} layer={layer} sourceId={id} getBeforeId={getBeforeId} />
        ))}
      </Source>
    </>
  );
});

const WFSLayer = observer(
  ({
    layer,
    currentLayer,
    sourceId,
    getBeforeId,
  }: {
    layer: any;
    currentLayer: any;
    sourceId: string;
    getBeforeId: (id: string) => string;
  }) => {
    const {
      publicationStore: { sortedLayers },
    } = useContext(StoresContext);

    const layerId = useMemo(() => createLayerId(sourceId, layer.id), [sourceId, layer.id]);
    const { visible } = useMemo(() => {
      const useSortedLayers = getFlatSortedLayers(sortedLayers);
      return useSortedLayers.find((layer) => layer.id === layerId) || { visible: true };
    }, [sortedLayers, layerId]);

    if (layer.paint["fill-opacity"]) layer.paint["fill-opacity"] = visible ? layer.paint["fill-opacity"] : 0;
    if (layer.paint["circle-opacity"]) layer.paint["circle-opacity"] = visible ? layer.paint["circle-opacity"] : 0;
    if (layer.paint["line-opacity"]) layer.paint["line-opacity"] = visible ? layer.paint["line-opacity"] : 0;

    return <Layer id={layerId} key={layerId} source={sourceId} type={layer.type} paint={{ ...layer.paint }} beforeId={getBeforeId(layerId)} />;
  },
);

export default withStyles(useMapStyles)(WfsLayerView);

const getLayerType = (name, layer: WfsTransferLayerListObject): "fill" | "line" | "circle" => {
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#layer-properties
  const {
    featureType: { Type },
  } = layer.featureTypes.filter((obj) => obj?.featureType?.Name === name)[0];
  switch (Type) {
    case "Point":
    case "MultiPoint":
      return "circle";
    case "LineString":
    case "MultiLineString":
      return "line";
    default:
      return "fill";
  }
};

const getLayerPaint = (name, layer: WfsTransferLayerListObject): mapboxgl.AnyPaint => {
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-property
  const {
    featureType: { Type },
    fillColor,
    borderColor,
    opacity,
  } = layer.featureTypes.filter((obj) => obj?.featureType?.Name === name)[0];
  switch (Type) {
    case "Point":
    case "MultiPoint":
      return {
        "circle-radius": 3,
        "circle-color": typeof fillColor === "string" ? fillColor : fillColor.css.backgroundColor,
        "circle-opacity": opacity * 0.01,
      };
    case "LineString":
    case "MultiLineString":
      return {
        "line-color": typeof fillColor === "string" ? fillColor : fillColor.css.backgroundColor,
        "line-opacity": opacity * 0.01,
        "line-width": 3,
      };
    default:
      return {
        "fill-color": typeof fillColor === "string" ? fillColor : fillColor.css.backgroundColor,
        "fill-outline-color": typeof borderColor === "string" ? borderColor : borderColor.css.backgroundColor,
        "fill-opacity": opacity * 0.01,
      };
  }
};
