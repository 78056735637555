// disabling flowtype to keep this example super simple
// It matches
/* eslint-disable flowtype/require-valid-file-annotation */

import { Box, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OpenInNewTabIcon from "@material-ui/icons/Launch";
import ReorderIcon from "@material-ui/icons/Reorder";
import { Alert } from "@material-ui/lab";
import { BaseLayerListObjectType, MapGroupItem, MapLayerItem, MapLayerOrderItem } from "@orbit/geo-core-shared";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { InjectedIntl } from "react-intl";
import messages from "../messages";

// a little function to help us with reordering the result
const reorder = (list: MapLayerOrderItem[], startIndex, endIndex): MapLayerOrderItem[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderBase = (list: BaseLayerListObjectType[], startIndex, endIndex): BaseLayerListObjectType[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const isSortedLayersV2 = (sortedLayers?: MapLayerOrderItem[] | MapGroupItem[]) => {
  return !sortedLayers?.length || "children" in (sortedLayers[0] || {});
};

export const getFlatSortedLayers = (sortedLayers: MapLayerOrderItem[] | MapGroupItem[]) => {
  let useSortedLayers = sortedLayers;
  if (isSortedLayersV2(sortedLayers as MapLayerOrderItem[] | MapGroupItem[])) {
    useSortedLayers = (sortedLayers as MapGroupItem[])?.reduce((ac: MapLayerItem[], g: MapGroupItem) => [...ac, ...g.children], []) as MapLayerItem[];
  }
  return (useSortedLayers as MapLayerOrderItem[]) || [];
};

export const GotoViewer = ({ label }) => (
  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} marginTop={0.5}>
    <Typography variant="body2" style={{ fontSize: 12, marginRight: 2 }}>
      <b>
        <u>{label}</u>
      </b>
    </Typography>
    <OpenInNewTabIcon style={{ width: "0.6em", height: "0.6em" }} />
  </Box>
);

export const SortLayers = observer(({ layers, onGotoViewer, intl }: { layers: MapLayerOrderItem[] | MapGroupItem[]; onGotoViewer: () => void; intl: InjectedIntl }) => {
  const {
    publicationStore: { setOrder },
  } = useContext(StoresContext);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(layers || [], result.source.index, result.destination.index);

    setOrder(items);
  }

  if (!layers.length) {
    return null;
  }

  return (
    <>
      <Alert severity="warning" onClick={onGotoViewer} style={{ cursor: "pointer" }}>
        <Typography variant="body2" style={{ fontSize: 13 }}>
          {intl.formatMessage(messages.publicationsOldOrderWarning)}
          <GotoViewer label={intl.formatMessage(messages.publicationsGotoViewer)} />
        </Typography>
      </Alert>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <List dense ref={droppableProvided.innerRef}>
              {layers.map((layer, index) => {
                const { id } = layer;
                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(draggableProvided, draggableSnapshot) => <SortItem layer={layer} draggableProvided={draggableProvided} layers={layers as MapLayerOrderItem[]} />}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
});

const SortItem = observer(
  ({
    layer,
    draggableProvided,
    layers,
  }: {
    layer: MapLayerOrderItem;
    draggableProvided: any;
    layers: MapLayerOrderItem[];
  }) => {
    const { type, label, id } = layer;
    const {
      publicationStore: { saveLayerOrder },
    } = useContext(StoresContext);

    return (
      <ListItem key={id} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
        <ListItemIcon>
          <ReorderIcon />
        </ListItemIcon>
        <ListItemText primary={`[${type}] ${label}`} />
      </ListItem>
    );
  },
);

export const SortBaseLayers = observer(() => {
  const {
    publicationStore: { setSortedBaseLayers, sortedBaseLayers },
  } = useContext(StoresContext);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderBase(sortedBaseLayers || [], result.source.index, result.destination.index);

    setSortedBaseLayers(items);
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(droppableProvided, droppableSnapshot) => (
          <List dense ref={droppableProvided.innerRef}>
            {sortedBaseLayers.map((layer, index) => {
              const { id } = layer;
              return (
                <Draggable key={id} draggableId={id} index={index}>
                  {(draggableProvided, draggableSnapshot) => <SortBaseItem baselayer={layer} draggableProvided={draggableProvided} />}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const SortBaseItem = observer(({ baselayer, draggableProvided }: { baselayer: BaseLayerListObjectType; draggableProvided: any }) => {
  const { title, id } = baselayer;
  return (
    <ListItem key={id} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
      <ListItemIcon>
        <ReorderIcon />
      </ListItemIcon>
      <ListItemText primary={`${title}`} />
    </ListItem>
  );
});
