import { TransferLayer } from "@orbit/geo-core-shared";
import axios from "axios";
const parser = require("fast-xml-parser");

const options = {
  ignoreNameSpace: true,
  parseAttributeValue: true,
  //attrNodeName: "attr",
  ignoreAttributes: false,
};

const { API_URL, WFS_CONVERTER_AF_URL } = window.env;

const CancelToken = axios.CancelToken;

export const fetchWfsCapabilities = async (url: string, username?: string, password?: string): Promise<any> => {
  try {
    const auth =
      username && password && username !== "" && password !== ""
        ? {
            username,
            password,
          }
        : undefined;
    const result = await axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      auth,
      params: {
        request: "GetCapabilities",
        service: "WFS",
      },
    });

    if (parser.validate(result.data) === true) {
      //optional (it'll return an object in case it's not valid)
      // console.log(parser.parse(result.data, options));

      let {
        WFS_Capabilities: {
          FeatureTypeList: { FeatureType },
          Filter_Capabilities: { Scalar_Capabilities: { ComparisonOperators: { ComparisonOperator } } },
          OperationsMetadata: { Operation },
        },
      } = parser.parse(result.data, options);

      const values = Operation.filter((op) => op["@_name"] === "GetFeature")[0].Parameter.filter((param) => param["@_name"] === "outputFormat")[0].AllowedValues.Value;
      const allowedValues = Array.isArray(values) ? values : [values];
      const applicationJson = allowedValues.filter((value) => value === "application/json")?.[0];
      const geojson = allowedValues.filter((value) => value === "GEOJSON")?.[0];

      const outputFormat = applicationJson || geojson || "NOT SUPPORTED";

      if (typeof ComparisonOperator[0] === "object") {
        //@ts-ignore
        ComparisonOperator = ComparisonOperator.map((value) => Object.values(value)[0]);
      }
      //console.log(FeatureType);
      return { FeatureType, ComparisonOperator, outputFormat };
    } else {
      return false;
    }
  } catch (e) {
    console.log("[fetchWfsCapabilities] First attempt failed, might be CORS related, so we try through API");
    try {
      const { data } = await axios.post(API_URL + `geo-core/transferlayers/fetchWfsCapabilities`, {
        url,
        username,
        password,
      });
      if (parser.validate(data.data) === true) {
        //optional (it'll return an object in case it's not valid)
        //console.log(parser.parse(result.data, options));
        let {
          WFS_Capabilities: { FeatureTypeList: { FeatureType }, Filter_Capabilities: { Scalar_Capabilities: { ComparisonOperators: { ComparisonOperator } } } },
        } = parser.parse(data.data, options);

        if (typeof ComparisonOperator[0] === "object") {
          //@ts-ignore
          ComparisonOperator = ComparisonOperator.map((value) => Object.values(value)[0]);
        }
        //console.log(FeatureType);
        return { FeatureType, ComparisonOperator };
      } else {
        return false;
      }
    } catch (e) {
      console.log("Error fetchWfsCapabilities: ", e.toString());
      return false;
    }
  }
};

export const fetchWmsCapabilities = async (url: string, username?: string, password?: string): Promise<any> => {
  try {
    const auth =
      username && password && username !== "" && password !== ""
        ? {
            username,
            password,
          }
        : undefined;
    const result = await axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      params: {
        request: "GetCapabilities",
        service: "WMS",
      },
      auth,
    });
    if (result.data === undefined) return false;
    if (parser.validate(result.data) === true) {
      //optional (it'll return an object in case it's not valid)
      const parsedData = parser.parse(result.data);
      const {
        //@ts-ignore
        Capability: { Layer: { Layer } },
      } = Object.values(parsedData)[0];
      return Layer;
    } else {
      return false;
    }
  } catch (e) {
    console.log("Error fetchWmsCapabilities: ", e.toString());
    return false;
  }
};

export const describeWfsFeatureType = async (name: string, url: string, username?: string, password?: string): Promise<any> => {
  try {
    const auth =
      username && password && username !== "" && password !== ""
        ? {
            username,
            password,
          }
        : undefined;
    const result = await axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      params: {
        request: "DescribeFeatureType",
        typeNames: name,
        service: "WFS",
        outputFormat: "application/json",
      },
      auth,
    });
    if (result.data.featureTypes.length > 0 && result.data.featureTypes[0].typeName !== null) return result.data.featureTypes[0];
    return false;
  } catch (e) {
    console.log("[describeWfsFeatureType] First attempt failed, might be CORS related, so we try through API");
    try {
      const { data } = await axios.post(API_URL + `geo-core/transferlayers/describeFeatureType`, {
        url,
        name,
        username,
        password,
      });
      if (data.data.featureTypes.length > 0 && data.data.featureTypes[0].typeName !== null) return data.data.featureTypes[0];
      return false;
    } catch (e) {
      console.log("Error describeWfsFeatureType: ", e.toString());
      return false;
    }
  }
};

export const fetchWfsFeatureInfo = async (name: string, url: string, username?: string, password?: string, outputFormat?: string): Promise<any> => {
  try {
    const auth =
      username && password && username !== "" && password !== ""
        ? {
            username,
            password,
          }
        : undefined;
    const result = await axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      params: {
        request: "GetFeature",
        typeNames: name,
        service: "WFS",
        count: 1,
        outputFormat,
      },
      auth,
    });

    if (result.data.features.length > 0 && result.data.features[0].geometry !== null) return result.data.features[0];
    return false;
  } catch (e) {
    console.log("[fetchWfsFeatureInfo] First attempt failed, might be CORS related, so we try through API");
    try {
      const { data } = await axios.post(API_URL + `geo-core/transferlayers/fetchWfsFeatureInfo`, {
        url,
        name,
        username,
        password,
      });
      if (data.data.features.length > 0 && data.data.features[0].geometry !== null) return data.data.features[0];
      return false;
    } catch (e) {
      console.log("Error fetchWfsFeatureInfo: ", e.toString());
      return false;
    }
  }
};

/**
 * dictionary of canceltokens because multiple requests can happen
 * when multiple wfs layers are coupled to a publiction
 * the id is used to store the token, and when a same id is given
 * the canceltoken is called
 */
const cancelTokens = {};

export const fetchWfsFeatures = async (transferLayer: TransferLayer, bbox: number): Promise<any> => {
  try {
    if (cancelTokens[transferLayer.id] !== undefined) {
      cancelTokens[transferLayer.id]();
    }

    const result = await axios.post(
      `${WFS_CONVERTER_AF_URL}/api/get-geojson`,
      JSON.stringify({
        ...transferLayer,
        bbox,
        // bboxCrs: 4326, // At the moment 4326 is the only supported crs and set as default
        outputCrs: 4326,
        featureTypes: transferLayer?.featureTypes || [],
      }),
      {
        headers: { "Content-Type": "application/json" },
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
        cancelToken: new CancelToken(function executor(c) {
          cancelTokens[transferLayer.id] = c;
        }),
      },
    );

    return result?.data?.features || [];
  } catch (e) {
    console.log("Error fetchWfsFeatures: ", e.message);
    return [];
  }
};

export const createTransferLayer = async ({
  type,
  name,
  url,
  data,
  username,
  password,
  outputFormat,
  defaultCrs,
  flipCoordinates,
  zoomLevel,
}: {
  type: string;
  name: string;
  url: string;
  data: any;
  username?: string;
  password?: string;
  outputFormat?: string;
  defaultCrs?: number;
  flipCoordinates?: boolean;
  zoomLevel?: number;
}): Promise<any> => {
  try {
    const layers = data.filter((layer) => layer.enabled);
    const result = await axios.post(API_URL + `geo-core/transferlayers/${type}`, {
      name,
      url,
      username,
      password,
      outputFormat,
      defaultCrs,
      flipCoordinates,
      zoomLevel,
      data: layers,
    });
    //console.log(result.data);
    return result.data.data;
  } catch (e) {
    console.log("Error createTransferLayer: ", e.toString());
    return false;
  }
};

export const fetchTransferLayers = async (includeShared?: boolean): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.get(API_URL + `geo-core/transferlayers?includeShared=${includeShared || false}`);
    return data;
  } catch (e) {
    console.log("Error loadTransferLayers: ", e.toString());
    return false;
  }
};

export const fetchTransferLayer = async (id: string): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.get(API_URL + `geo-core/transferlayers/${id}`);
    return data;
  } catch (e) {
    console.log("Error fetchTransferLayer: ", e.toString());
    return false;
  }
};

export const pushTransferLayer = async (id: string, updateData: any): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.post(API_URL + `geo-core/transferlayers/update/${id}`, updateData);
    return data;
  } catch (e) {
    console.log("Error pushTransferLayer: ", e.toString());
    return false;
  }
};

export const pushShareConfigTransferLayer = async (id: string, shareConfig: any): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.post(API_URL + `geo-core/transferlayers/update-share-config/${id}`, shareConfig);
    return data;
  } catch (e) {
    console.log("Error pushShareConfigTransferLayer: ", e.toString());
    return false;
  }
};

export const deleteTransferLayer = async (id: string): Promise<any> => {
  try {
    const {
      data: { data },
    } = await axios.delete(API_URL + `geo-core/transferlayers/${id}`);
    return data;
  } catch (e) {
    console.log("Error deleteTransferLayer: ", e.toString());
    return false;
  }
};
