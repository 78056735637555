import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MapIcon from "@material-ui/icons/Map";
import PaletteIcon from "@material-ui/icons/Palette";
import SettingsIcon from "@material-ui/icons/Settings";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { SelectChangeEvent } from "@mui/material/Select";
import { ConvertionTaskActionTypes, ConvertionTaskStatus, FileTypes, LayerTableObjectType, LinkDataTableType, MapLayerType, crsTypes } from "@orbit/geo-core-shared";
import clsx from "clsx";
import produce from "immer";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { ROUTE_DATAGROUPS, ROUTE_DATALAYERS, ROUTE_FILEMANAGER, ROUTE_PUBLICATIONS } from "routes/RouteList";
import { downloadDataLayer } from "services/geo-core";
import { useClipboard } from "use-clipboard-copy";
import { errorsType, getFileTypeForValidatorType, groupedFilesType } from "utils/file.utils";
import { getItemMetaLabel } from "utils/string";
import CustomDialogView from "views/CustomDialog/CustomDialogView";
import BaseMap from "views/Map/BaseMap";
import { BaseMapActionTypes, useBaseMap } from "views/Map/BaseMapContext";
import SimpleTable from "views/Table/SimpleTable";
import FileUploader from "views/upload/FileUploader";
import { getConvertionTaskStatusLabel } from "../../../constants";
import { fetchLinkableLayers, linkDataTable, loadFileCount, unlinkDataTable, updateDataLayer } from "../../../services/geo-core";
import { firstLetterUpperCase } from "../../../utils";
import useDatalayerDetailStyles from "./DatalayerDetailStyles";
import DatalayerImageForm, { ImageLayerFormAction, NoDataColorTypes } from "./DatalayerImageForm";
import { allowedTypes } from "./Datalayers";
import EditDialog from "./EditTitleDialog";
import messages from "./messages";

const getImageLayerInfo = (style: any) => {
  const defaultReturn = { url: "", noData: NoDataColorTypes.NONE };
  if (!style) return defaultReturn;
  const titilerUrl = style.sources?.["orbit-source"]?.tiles?.[0] || "";
  if (!titilerUrl) return defaultReturn;
  const url = new URL(titilerUrl);
  return { url: url.searchParams.get("url") || "", noData: url.searchParams.get("nodata") || NoDataColorTypes.NONE };
};

const ACTION_TYPES = [
  { value: ConvertionTaskActionTypes.APPEND, label: "Toevoegen" },
  { value: ConvertionTaskActionTypes.OVERWRITE, label: "Overschrijven" },
];

const styles = {
  root: {
    marginLeft: 5,
  },
};

const SpinnerAdornment = withStyles(styles)((props) => <CircularProgress size={20} style={{ marginLeft: 10 }} />);
export const AdornedButton = (props) => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest} disabled={!!loading || props.disabled}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  urlButton: {
    float: "right",
  },
}));

const DatalayerDetail: FunctionComponent<{
  classes: any;
  intl: InjectedIntl;
}> = observer(({ classes, intl, intl: { formatMessage } }) => {
  const {
    uploadStore,
    dataLayerStore: {
      layers,
      getActiveLayer,
      clearActiveLayer,
      updateStyle,
      activeLayer,
      baseLayers,
      esExists,
      esIndex,
      createLocationIndex,
      updateLocationIndex,
      updateFileMapping,
      resetBoundingboxOfDatalayer,
    },
    publicationStore: { publications, loadPublications },
  } = useContext(StoresContext);

  let { push } = useHistory();

  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useBaseMap();
  const [datalayerTypeValue, setDatalayerTypeValue] = useState("geojson");
  const [crsValue, setCrsValue] = useState(crsTypes[0].crs);
  const [loading, setLoading] = useState(false);

  const [allowedFileTypes] = useState(allowedTypes["VECTOR"]);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [overwrite, setOverwrite] = React.useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [groupedFiles, setGroupedFiles] = useState<groupedFilesType>({});
  const [errors, setErrors] = useState<errorsType>([]);
  const [actionType, setActionType] = useState<ConvertionTaskActionTypes>(ACTION_TYPES[0].value);
  const [fileMapping, setFileMapping] = React.useState<string[]>([]);
  const [linkedTableDisabled, setLinkedTableDisabled] = React.useState<boolean>(false);
  const [showBboxRemovePopup, setShowBboxRemovePopup] = React.useState<boolean>(false);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "warning">("success");
  const [message, setMessage] = useState<string>("");
  const [fileCount, setFileCount] = useState<object>({});
  const [linkableTables, setLinkableTables] = useState<LayerTableObjectType[]>([]);

  const handleFileMappingChange = (event: SelectChangeEvent<typeof fileMapping>) => {
    const {
      target: { value },
    } = event;
    setFileMapping(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleFileMappingClick = (id: string, fileMapping: string[]) => {
    updateFileMapping({
      id,
      fileMapping,
    });
    setMessage("Koppeling opgeslagen.");
    setAlertType("success");
    setAlertOpen(true);
  };

  const clipboard = useClipboard();

  useEffect(() => {
    loadPublications();
  }, []);

  useEffect(() => {
    return () => dispatch({ type: BaseMapActionTypes.RESET });
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }
    getActiveLayer(id);
    return () => {
      clearActiveLayer();
    };
  }, [layers, id]);

  useEffect(() => {
    const fetchCount = async () => {
      const fileCount = await loadFileCount();
      setFileCount(fileCount);
    };
    if (activeLayer) {
      setFileMapping(activeLayer.fileMapping);
      fetchCount();
    }
  }, [activeLayer]);

  const changeLayerName = async (newName) => {
    await updateDataLayer(id, { displayName: newName });
    getActiveLayer(id);
  };

  const changeLayerId = async (newName) => {
    await updateDataLayer(id, { layerId: newName });
    getActiveLayer(id);
  };

  const handleAllowPublicationBboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (!checked) {
      setShowBboxRemovePopup(true);
    } else {
      await updateDataLayer(id, { allowPublicationBbox: checked });
      getActiveLayer(id);
    }
  };

  const handleShowBboxRemovePopupClose = async () => {
    await updateDataLayer(id, { allowPublicationBbox: false });
    getActiveLayer(id);
    setShowBboxRemovePopup(false);
  };

  const handleRadioChange = (event) => {
    setDatalayerTypeValue(event.target.value);
  };
  const handleChange = (event) => {
    setCrsValue(event.target.value);
  };

  const handleDownload = async (event) => {
    event.preventDefault();
    setLoading(true);
    const response = await downloadDataLayer(id, datalayerTypeValue, crsValue);
    const fileName = activeLayer?.layerName;
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${fileName}.${datalayerTypeValue === "geojson" ? "json" : "zip"}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  };

  useEffect(() => {
    async function getData() {
      const tableLayersData = await fetchLinkableLayers();
      setLinkableTables(tableLayersData.filter((tableLayer) => tableLayer.id === activeLayer?.linkedTableData?.linkedId));
    }
    getData();
  }, [activeLayer?.linkedTableData]);

  const sortedLayers = [
    {
      id: "LG",
      type: MapLayerType.LAYER_GROUP,
      visible: true,
      label: formatMessage(messages.datalayersLG),
    },
  ];

  const processing = activeLayer && activeLayer?.status !== ConvertionTaskStatus.ERROR && activeLayer?.status !== ConvertionTaskStatus.FINISHED;

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const clearFiles = async () => {
    setFiles([]);
    setErrors([]);
    setGroupedFiles({});
    setActionType(ConvertionTaskActionTypes.APPEND);
  };

  const uploadFiles = async () => {
    if (!files?.length) return;

    setLoading(true);
    await uploadStore.upload(groupedFiles, undefined, actionType, id);
    clearFiles();
    await getActiveLayer(id);
    setLoading(false);
  };

  const pushLocationIndex = async (id: string) => {
    const success = await createLocationIndex(id);
    if (success) {
      window.location.reload();
    }
  };

  const refreshLocationIndex = async (id: string, overwrite: boolean) => {
    const success = await updateLocationIndex(id, overwrite);
    if (success) {
      window.location.reload();
    }
  };

  const relatedPublications = publications.filter((publication) => activeLayer?.layerGroups.some((group) => group.id === publication.layerGroup?.id));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const CopyButton = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const handleCopyClick = () => {
      navigator.clipboard.writeText(esIndex).then(() => {
        // Set the state to true when the copy is successful
        setIsCopied(true);

        // Reset the state after 3 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      });
    };
    return (
      <Tooltip title={esIndex}>
        <IconButton
          // color={isCopied ? "success" : "primary"}
          color="primary"
          aria-label="copy"
          onClick={handleCopyClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            border: isHovered ? "1px solid rgba(56, 111, 164, 1)" : "1px solid rgba(56, 111, 164, 0.5)",
            borderRadius: "4px",
            padding: "6px",
          }}
        >
          {isCopied ? <DoneIcon sx={{ fontSize: "20px" }} /> : <ContentCopyIcon sx={{ fontSize: "20px" }} />}
        </IconButton>
      </Tooltip>
    );
  };

  useEffect(() => {
    const filtered = linkableTables?.[0]?.data?.csvMetaData?.fields?.filter((columnName) => fileMapping.includes(columnName));
    if (filtered) {
      setLinkedTableDisabled(filtered.length > 0);
    } else {
      setLinkedTableDisabled(false);
    }
  }, [fileMapping, linkableTables]);

  const handleResetBbox = async () => {
    const success = await resetBoundingboxOfDatalayer(activeLayer?.id as string);
    if (success) {
      getActiveLayer(id);
    } else {
      setAlertType("error");
      setMessage(formatMessage(messages.datalayersResetBboxError));
      setAlertOpen(true);
    }
  };

  return (
    <Fragment>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          {activeLayer && activeLayer?.status !== ConvertionTaskStatus.ERROR && activeLayer?.status !== ConvertionTaskStatus.FINISHED && (
            <Alert style={{ marginBottom: 20 }} severity="warning">
              <Tooltip title={getConvertionTaskStatusLabel(activeLayer.status)}>
                <span>{formatMessage(messages.datalayersStillProcessing)}</span>
              </Tooltip>
            </Alert>
          )}
          {activeLayer && activeLayer?.status === ConvertionTaskStatus.ERROR && (
            <Alert style={{ marginBottom: 20 }} severity="error">
              {formatMessage(messages.datalayersSomethingWrong)}
            </Alert>
          )}
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="h4" gutterBottom>
              {activeLayer?.displayName}
              <EditDialog
                key={activeLayer?.displayName}
                label={formatMessage(messages.datalayersDisplayName)}
                defaultValue={activeLayer?.displayName}
                onSave={(newName) => changeLayerName(newName)}
                disabled={activeLayer?.status !== ConvertionTaskStatus.FINISHED && activeLayer?.status !== ConvertionTaskStatus.ERROR}
                formatMessage={formatMessage}
                title={formatMessage(messages.datalayersDisplayNameUpdateTitle)}
              />
              {[FileTypes.SHP, FileTypes.GEOJSON].includes(activeLayer?.fileType) && (
                <IconButton disabled={activeLayer?.status !== ConvertionTaskStatus.FINISHED} onClick={() => push(ROUTE_DATALAYERS + "/style/" + id)}>
                  <PaletteIcon />
                </IconButton>
              )}
            </Typography>
            {[FileTypes.SHP, FileTypes.GEOJSON].includes(activeLayer?.fileType) && (
              <div style={{ textAlign: "right", marginBottom: 10, marginLeft: 10 }}>
                <Button disabled={loading || !!processing} variant="contained" color="primary" size="large" startIcon={<MapIcon />} onClick={openDialog}>
                  {formatMessage(messages.datalayersUpdate)}
                </Button>
              </div>
            )}
          </div>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems={"flex-end"}>
            <Box>
              {[FileTypes.SHP, FileTypes.GEOJSON].includes(activeLayer?.fileType) && (
                <>
                  <Typography style={{ fontSize: "1em" }}>
                    {`${formatMessage(messages.datalayersLayerName)}: `}
                    <b>{activeLayer?.layerName?.replace("public.", "")}</b>
                    <IconButton style={{ marginLeft: 5 }} size="small" onClick={() => clipboard.copy(activeLayer?.layerName?.replace("public.", ""))}>
                      <FileCopyIcon />
                    </IconButton>
                  </Typography>
                  <Typography style={{ fontSize: "1em" }} gutterBottom>
                    {`${formatMessage(messages.datalayersLayerId)}: `}
                    <b>{activeLayer?.layerId}</b>
                    <EditDialog
                      key={activeLayer?.displayName}
                      label={formatMessage(messages.datalayersLayerId)}
                      defaultValue={activeLayer?.layerId}
                      onSave={(newName) => changeLayerId(newName)}
                      disabled={activeLayer?.status !== ConvertionTaskStatus.FINISHED}
                      formatMessage={formatMessage}
                      title={formatMessage(messages.datalayersLayerIdUpdateTitle)}
                      iconProps={{ style: { marginLeft: 5 }, size: "small" }}
                    />
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" gutterBottom>
                {getItemMetaLabel({ intl, item: activeLayer })}
              </Typography>
            </Box>
            <Box mb={1}>
              {[FileTypes.SHP, FileTypes.GEOJSON].includes(activeLayer?.fileType) && (
                <Button onClick={handleResetBbox} disabled={activeLayer?.status !== ConvertionTaskStatus.FINISHED && activeLayer?.id} variant="outlined">
                  {formatMessage(messages.datalayersResetBbox)}
                </Button>
              )}
            </Box>
          </Box>
          <div style={{ width: "100%", height: 500 }}>
            <BaseMap
              bounds={activeLayer?.bbox}
              latLng={{
                lat: 50.98935,
                lng: 3.87118,
              }}
              mapStyle={activeLayer?.status === ConvertionTaskStatus.FINISHED ? activeLayer?.style : null}
              baseLayers={baseLayers}
              sortedLayers={sortedLayers}
            />
          </div>
          {state?.relationalItems?.length > 0 && <SimpleTable data={state.relationalItems} />}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            {formatMessage(messages.datalayersPublicURLS)}
          </Typography>
          <Grid container direction="column" spacing={2}>
            {activeLayer?.mapServices.map((mapService) => (
              <Grid key={mapService.url} item>
                <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                  <InputLabel htmlFor="standard-adornment-password">
                    {firstLetterUpperCase(mapService.type)} {formatMessage(messages.datalayersURL)}
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type="text"
                    fullWidth
                    value={mapService.url}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label={`${formatMessage(messages.datalayersCopy)} ${mapService.type}`} onClick={() => clipboard.copy(mapService.url)}>
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
          <br />
          {activeLayer?.fileType === FileTypes.IMAGE && (
            <>
              <Typography variant="h5" gutterBottom style={{ marginTop: 10 }}>
                {formatMessage(messages.datalayersImageConfiguration)}
              </Typography>
              <DatalayerImageForm
                action={ImageLayerFormAction.UPDATE}
                defaultImageLayerUrl={getImageLayerInfo(activeLayer.style).url}
                defaultNoDataColor={getImageLayerInfo(activeLayer.style).noData as NoDataColorTypes}
              />
            </>
          )}
          {[FileTypes.SHP, FileTypes.GEOJSON].includes(activeLayer?.fileType) && (
            <>
              <Typography variant="h5" gutterBottom>
                {formatMessage(messages.datalayersDatagroups)}
              </Typography>
              {activeLayer?.layerGroups.length ? (
                activeLayer.layerGroups.map((layerGroup) => (
                  <div
                    key={layerGroup.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                      cursor: "pointer",
                      paddingLeft: "8px",
                    }}
                    onClick={() => {
                      push(`${ROUTE_DATAGROUPS}/${layerGroup.id}`);
                    }}
                  >
                    <LinkIcon fontSize="small" />
                    <div style={{ paddingLeft: "8px" }}>{layerGroup.name}</div>
                  </div>
                ))
              ) : (
                <div>{formatMessage(messages.datalayersNoLinkedDatagroup)}</div>
              )}

              <br />
              <Typography variant="h5" gutterBottom>
                {formatMessage(messages.datalayersPublications)}
              </Typography>
              {relatedPublications.length ? (
                relatedPublications.map((publication) => (
                  <div
                    key={publication.id}
                    style={{ display: "flex", alignItems: "center", marginBottom: "4px", paddingLeft: "8px" }}
                    onMouseEnter={(e) => ((e.target as HTMLElement).style.opacity = "0.8")}
                    onMouseLeave={(e) => ((e.target as HTMLElement).style.opacity = "1")}
                  >
                    <LinkIcon fontSize="small" />
                    <div
                      style={{ cursor: "pointer", paddingLeft: "8px" }}
                      onClick={() => {
                        push(`${ROUTE_PUBLICATIONS}/${publication.id}`);
                      }}
                    >
                      {publication.name}
                    </div>
                  </div>
                ))
              ) : (
                <div>{formatMessage(messages.datalayersNoLinkedPublication)}</div>
              )}
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    // disabled
                    checked={activeLayer?.allowPublicationBbox}
                    onChange={handleAllowPublicationBboxChange}
                    name="allowPublicationBbox"
                  />
                }
                label={formatMessage(messages.datalayersBBOXCheckbox)}
                style={{ marginBottom: 10 }}
              />
              <Typography variant="h5" gutterBottom>
                {formatMessage(messages.datalayersLinkedTableData)}
              </Typography>
              {activeLayer && (
                <LindedTableData
                  parentId={activeLayer.id}
                  columnInfo={activeLayer.columnInfo}
                  linkedTableData={activeLayer.linkedTableData}
                  linkedTableDisabled={linkedTableDisabled}
                  formatMessage={formatMessage}
                />
              )}
              <br />
              <br />
              {activeLayer && activeLayer?.status !== ConvertionTaskStatus.ERROR && (
                <div className={classes.urlContainer}>
                  <Button className={classes.urlButton} onClick={() => push(ROUTE_DATALAYERS + `/${id}/upload`)} variant="outlined" size="small" startIcon={<CloudUploadIcon />}>
                    {formatMessage(messages.datalayersFilesUpload)}
                  </Button>
                  <Button className={classes.urlButton} onClick={() => push(ROUTE_FILEMANAGER + `?id=${id}`)} variant="outlined" size="small" startIcon={<SettingsIcon />}>
                    {formatMessage(messages.datalayersFilesManage)}
                  </Button>
                  <Typography variant="h5" gutterBottom>
                    {formatMessage(messages.datalayersFiles)}
                  </Typography>
                  <FormLabel>{formatMessage(messages.datalayersFilesAttach)}</FormLabel>
                  <Select
                    id="fileMapping"
                    disabled={activeLayer ? fileCount[activeLayer.id] > 0 : false}
                    multiple
                    fullWidth
                    value={fileMapping}
                    onChange={handleFileMappingChange}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {activeLayer?.columnInfo?.map((info) => (
                      <MenuItem key={info.columnName} value={info.columnName}>
                        <Checkbox checked={fileMapping.indexOf(info.columnName) > -1} />
                        <ListItemText primary={info.columnName} />
                      </MenuItem>
                    ))}
                    {linkableTables?.[0]?.data?.csvMetaData?.fields?.map((columnName) => (
                      <MenuItem key={`linkedTable-${columnName}`} value={columnName}>
                        <Checkbox checked={fileMapping.indexOf(columnName) > -1} />
                        <ListItemText primary={columnName} />
                      </MenuItem>
                    ))}
                  </Select>
                  {activeLayer && fileCount[activeLayer.id] > 0 && <Typography>{formatMessage(messages.datalayersFilesError)}</Typography>}
                  <Button fullWidth disabled={activeLayer ? fileCount[activeLayer.id] > 0 : false} onClick={() => handleFileMappingClick(activeLayer?.id || "", fileMapping)}>
                    {formatMessage(messages.datalayersFilesSave)}
                  </Button>
                </div>
              )}
              <Typography variant="h5" gutterBottom>
                {formatMessage(messages.datalayersDownloadDatalayer)}
              </Typography>
              <form onSubmit={handleDownload}>
                <FormControl component="fieldset" fullWidth className={classes.formControl}>
                  <RadioGroup aria-labelledby="datalayer-type" name="datalayer-type" value={datalayerTypeValue} onChange={handleRadioChange}>
                    <FormControlLabel value="geojson" control={<Radio />} label="Geojson" />
                    <FormControlLabel value="shape" control={<Radio />} label="Shape" />
                  </RadioGroup>
                  <Select defaultValue={crsValue} onChange={handleChange}>
                    {crsTypes.map((item) => (
                      <MenuItem key={item.crs} value={item.crs}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <br />
                  <AdornedButton
                    className={classes.button}
                    fullWidth
                    variant="outlined"
                    type="submit"
                    color="primary"
                    startIcon={<CloudDownloadIcon />}
                    loading={loading}
                    disabled={activeLayer?.status !== ConvertionTaskStatus.FINISHED}
                  >
                    {formatMessage(messages.datalayersDownloadDatalayer)}
                  </AdornedButton>
                </FormControl>
              </form>
              <Typography variant="h5" gutterBottom style={{ marginTop: 10 }}>
                {formatMessage(messages.datalayersLocationIndex)}
              </Typography>
              {esExists ? (
                <>
                  <Typography variant="body2" gutterBottom>
                    {formatMessage(messages.datalayersESExists)}
                  </Typography>
                  <FormControlLabel
                    value={overwrite}
                    onChange={() => setOverwrite(!overwrite)}
                    control={<Checkbox />}
                    label={formatMessage(messages.datalayersOverwriteModifiedLocations)}
                    labelPlacement="end"
                  />
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AdornedButton
                      className={classes.button}
                      onClick={() => refreshLocationIndex(id, overwrite)}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      style={{ marginRight: "16px", width: "98%" }}
                    >
                      {formatMessage(messages.datalayersUpdateES)}
                    </AdornedButton>
                    <CopyButton />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="body2" gutterBottom>
                    {formatMessage(messages.datalayersNoES)}
                  </Typography>
                  <AdornedButton className={classes.button} onClick={() => pushLocationIndex(id)} fullWidth variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>
                    {formatMessage(messages.datalayersCreateES)}
                  </AdornedButton>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          clearFiles();
          closeDialog();
        }}
        dialogTitle={formatMessage(messages.datalayersUpdateDatalayer)}
        dialogContent={
          <Fragment>
            <>
              <FormControl className={classes.formControl} style={{ marginBottom: 20 }} fullWidth>
                <InputLabel id="csv-update-label">{formatMessage(messages.datalayersDataAction)}</InputLabel>
                <Select value={actionType} fullWidth onChange={(e) => setActionType(e.target.value as ConvertionTaskActionTypes)}>
                  {ACTION_TYPES.map((at) => (
                    <MenuItem key={at.value} value={at.value}>
                      {at.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FileUploader
                multiple
                accept={allowedFileTypes}
                onChange={(newfiles: File[], groupedFiles: groupedFilesType, errors: errorsType) => {
                  setFiles([]);
                  setGroupedFiles({});
                  if (errors?.length) {
                    setErrors(errors);
                    return;
                  }

                  if (newfiles.length === 0) {
                    setErrors([]);
                    setFiles([]);
                    setGroupedFiles({});
                    return;
                  }

                  if (Object.keys(groupedFiles).length !== 1) {
                    setErrors([
                      {
                        name: formatMessage(messages.datalayersTypeFilesError),
                        reason: `${formatMessage(messages.datalayersTypeFilesErrorReason)} (${activeLayer?.fileType})`,
                      },
                    ]);
                    return;
                  }

                  if (getFileTypeForValidatorType(Object.keys(groupedFiles)[0]) !== activeLayer?.fileType) {
                    setErrors([
                      {
                        name: formatMessage(messages.datalayersWrongFileTypeError),
                        reason: `${formatMessage(messages.datalayersWrongImportError)} ${activeLayer?.fileType} ${formatMessage(messages.datalayersWrongImportErrorReason)}`,
                      },
                    ]);
                    return;
                  }

                  if (Object.keys(groupedFiles[Object.keys(groupedFiles)[0]]).length !== 1) {
                    setErrors([{ name: formatMessage(messages.datalayersGroupFilesError), reason: `${formatMessage(messages.datalayersGroupFilesErrorReason)}` }]);
                    return;
                  }
                  setErrors([]);
                  setFiles(newfiles);
                  setGroupedFiles(groupedFiles);
                }}
              />
              {errors && errors.length > 0 && (
                <List subheader={<ListSubheader>{formatMessage(messages.datalayersErrors)}</ListSubheader>}>
                  {errors.map((error) => (
                    <ListItem key={error.name}>
                      <ListItemText primary={error.name} secondary={error.reason} />
                    </ListItem>
                  ))}
                </List>
              )}
              {files &&
                files.length > 0 &&
                groupedFiles &&
                Object.keys(groupedFiles).map((key) => (
                  <List key={key} subheader={<ListSubheader>{key}</ListSubheader>}>
                    {Object.keys(groupedFiles[key]).map((filekey) => (
                      <ListItem key={filekey}>
                        <ListItemText primary={filekey} secondary={groupedFiles[key][filekey].reduce((accumulator, currentValue) => accumulator + " " + currentValue.name, "")} />
                      </ListItem>
                    ))}
                  </List>
                ))}
            </>
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                clearFiles();
                closeDialog();
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant={"contained"}
              onClick={() => {
                uploadFiles();
                closeDialog();
              }}
              disabled={!!errors.length || !files.length}
            >
              {actionType === ConvertionTaskActionTypes.APPEND ? formatMessage(messages.datalayersAddShape) : formatMessage(messages.datalayersOverwriteActiveGeometry)}
            </Button>
          </Fragment>
        }
      />
      <CustomDialogView
        open={showBboxRemovePopup}
        handleClose={() => {
          setShowBboxRemovePopup(false);
        }}
        dialogTitle={formatMessage(messages.datalayersBBOXDialogTitle)}
        dialogContent={
          <Typography variant="body1" gutterBottom>
            {formatMessage(messages.datalayersBBOXDialogContent)}
          </Typography>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                setShowBboxRemovePopup(false);
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button id="submit" color="primary" variant={"contained"} onClick={handleShowBboxRemovePopupClose}>
              {formatMessage(messages.datalayersBBOXDialogConfirm)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(useDatalayerDetailStyles)(injectIntl(DatalayerDetail));

const initialState = {
  parentId: "",
  linkedId: "",
  linkedFields: [],
  isValid: false,
  parentColumnInfo: [],
  childColumnInfo: [],
  canLink: true,
};

function reducer(state, action) {
  let isValid: boolean;
  switch (action.type) {
    case "setLinkableTables":
      return { ...state, linkableTables: action.payload };
    case "setLinkedId":
      const { id, reset, canLink } = action.payload;
      const childColumnInfo = state.linkableTables.find((linkableTable) => linkableTable.id === id)?.data?.columnInfo;
      isValid = state.linkedFields[0]?.from?.length > 0 && state.linkedFields[0]?.to?.length > 0;

      return {
        ...state,
        linkedId: id,
        childColumnInfo: childColumnInfo,
        ...(reset && { linkedFields: [{ from: "", to: "" }] }),
        isValid,
        canLink,
      };

    case "setLinkedField":
      const { index, direction, value } = action.payload;
      const linkedFields = produce(state.linkedFields, (draft) => {
        draft[index][direction] = value;
      });

      isValid = linkedFields[0]?.from?.length > 0 && linkedFields[0]?.to?.length > 0;
      return {
        ...state,
        linkedFields,
        isValid,
      };
    case "canLink":
      return { ...state, canLink: action.payload };

    case "reset":
      return {
        ...state,
        linkedId: "",
        linkedFields: [{ from: "", to: "" }],
        isValid: false,
        canLink: true,
      };
    default:
      throw new Error();
  }
}

const LindedTableData = observer(
  ({
    parentId,
    columnInfo,
    linkedTableData,
    linkedTableDisabled,
    formatMessage,
  }: {
    parentId: string;
    columnInfo: any[];
    linkedTableData?: LinkDataTableType;
    linkedTableDisabled: boolean;
    formatMessage: any;
  }) => {
    const {
      dataLayerStore: { updateStyle },
    } = useContext(StoresContext);

    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const defaultState = { ...initialState, parentId, parentColumnInfo: columnInfo, ...linkedTableData };
    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
      async function getData() {
        const tableLayersData = await fetchLinkableLayers();
        dispatch({ type: "setLinkableTables", payload: tableLayersData });
        /**
         * when there is a linked table we need to set the linked id
         * so the popup has correct state
         */
        if (state.linkedId) {
          dispatch({ type: "setLinkedId", payload: { id: state.linkedId, reset: false, canLink: false } });
        }
      }
      getData();
    }, []);

    if (!state.linkableTables) {
      return <div>{formatMessage(messages.datalayersLoadingData)}</div>;
    }
    return (
      <div>
        <Grid container direction="column" spacing={2}>
          <FormControl className={classes.formControl}>
            <InputLabel id="linked-table-label">{formatMessage(messages.titleCSV)}</InputLabel>
            <Select
              labelId="linked-table-label"
              id="linked-table"
              disabled={!state.canLink}
              value={state.linkedId}
              fullWidth
              onChange={(event) => {
                dispatch({ type: "setLinkedId", payload: { id: event.target.value, reset: true, canLink: true } });
              }}
            >
              <MenuItem value="">
                <em>{formatMessage(messages.datalayersNone)}</em>
              </MenuItem>
              {state.linkableTables.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container direction="column" spacing={2}>
          {state.linkedId &&
            state.linkedFields.map((linkedField, index) => (
              <Fragment key={index}>
                <SelectColumn
                  label={formatMessage(messages.datalayersFrom)}
                  disabled={!state.canLink}
                  onChange={(event) => {
                    dispatch({ type: "setLinkedField", payload: { index, direction: "from", value: event.target.value } });
                  }}
                  data={state.parentColumnInfo}
                  value={linkedField.from}
                  formatMessage={formatMessage}
                />

                <SelectColumn
                  label={formatMessage(messages.datalayersTo)}
                  disabled={!state.canLink}
                  onChange={(event) => {
                    dispatch({ type: "setLinkedField", payload: { index, direction: "to", value: event.target.value } });
                  }}
                  data={state.childColumnInfo}
                  value={linkedField.to}
                  formatMessage={formatMessage}
                />
              </Fragment>
            ))}
        </Grid>

        <Grid container direction="column" spacing={2}>
          {state.isValid && state.canLink && (
            <AdornedButton
              className={classes.button}
              fullWidth
              variant="outlined"
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={async () => {
                setLoading(true);
                const { linkableTables, parentColumnInfo, childColumnInfo, ...linkData } = state;
                const style = await linkDataTable(linkData as LinkDataTableType);
                dispatch({ type: "canLink", payload: false });
                updateStyle(style);
                setLoading(false);
              }}
              loading={loading}
            >
              {formatMessage(messages.datalayersLinkTableData)}
            </AdornedButton>
          )}

          {!state.canLink && linkedTableDisabled && (
            <Tooltip title={formatMessage(messages.datalayersDeleteLinkDisabled)}>
              <span>
                <AdornedButton className={classes.button} fullWidth variant="outlined" color="secondary" startIcon={<DeleteIcon />} disabled loading={loading}>
                  {formatMessage(messages.datalayersDeleteLink)}
                </AdornedButton>
              </span>
            </Tooltip>
          )}
          {!state.canLink && !linkedTableDisabled && (
            <AdornedButton
              className={classes.button}
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={async () => {
                setLoading(true);
                const style = await unlinkDataTable(parentId);
                dispatch({ type: "reset", payload: defaultState });
                updateStyle(style);
                setLoading(false);
              }}
              startIcon={<DeleteIcon />}
              loading={loading}
            >
              {formatMessage(messages.datalayersDeleteLink)}
            </AdornedButton>
          )}
        </Grid>
      </div>
    );
  },
);

injectIntl(LindedTableData);

const SelectColumn = ({ onChange, data, value, label, disabled = false, formatMessage }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`linked-column-label-${label}`}>{label}</InputLabel>
      <Select disabled={disabled} labelId={`linked-column-label-${label}`} id="linked-column" value={value} onChange={onChange}>
        <MenuItem value="">
          <em>{formatMessage(messages.datalayersNoneEnglish)}</em>
        </MenuItem>
        {data?.map((item) => (
          <MenuItem key={item.columnName} value={item.columnName}>
            {item.columnName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

injectIntl(SelectColumn);
