import { Button, IconButton, makeStyles, TextField } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import EditIcon from "@material-ui/icons/Edit";
import { injectIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import messages from "./messages";

const useStyle = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  urlButton: {
    float: "right",
  },
}));

const EditDialog = observer(({ iconProps, defaultValue, onSave, label, title, disabled = false, intl: { formatMessage }, allowedCharacters, errorText }) => {
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultValue);
  const [showError, setShowError] = useState<boolean>(false);
  const classes = useStyle();

  const handleNameChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    if (allowedCharacters) {
      if (!allowedCharacters.test(value)) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
    setValue(value);
  };

  return (
    <>
      <CustomDialogView
        open={editDialogOpen}
        handleClose={() => {
          setEditDialogOpen(false);
        }}
        maxWidth={"sm"}
        dialogTitle={title}
        dialogContent={
          <Fragment>
            <TextField
              error={showError}
              className={classes.marginBottom}
              required
              label={label}
              value={value}
              onChange={(e) => handleNameChange(e)}
              helperText={showError ? errorText || "" : ""}
              fullWidth
            />
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                setEditDialogOpen(false);
              }}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button
              id="submit"
              color="primary"
              variant="contained"
              disabled={value?.length === 0 || disabled || showError}
              onClick={() => {
                setEditDialogOpen(false);
                onSave(value);
              }}
              disableElevation
            >
              {formatMessage(messages.datalayersSave)}
            </Button>
          </Fragment>
        }
      />

      <IconButton {...iconProps} onClick={() => setEditDialogOpen(true)} disabled={disabled}>
        <EditIcon />
      </IconButton>
    </>
  );
});

export default injectIntl(EditDialog);
