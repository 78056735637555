import { withStyles } from "@material-ui/styles";
import { createLayerId, WmsTransferLayerListObject } from "@orbit/geo-core-shared";
import { getFlatSortedLayers } from "containers/partials/publication/layers/SortLayers";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useMemo } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";
import useMapStyles from "./MapStyles";
const { API_URL } = window.env;

type WmsLayerViewType = {
  wmsLayers: WmsTransferLayerListObject[];
  getBeforeId: (id: string) => string | null;
};

const WmsLayerView: FunctionComponent<WmsLayerViewType> = observer(({ wmsLayers, getBeforeId }) => (
  <>
    {wmsLayers.map((wmsLayer) => (
      <WmsLayer wmsLayer={wmsLayer} getBeforeId={getBeforeId} />
    ))}
  </>
));

const WmsLayer = observer(({ wmsLayer, getBeforeId }: { wmsLayer: WmsTransferLayerListObject; getBeforeId: (id: string) => string | null }) => {
  const layerId = useMemo(() => createLayerId(wmsLayer.id), [wmsLayer.id]);
  const beforeId = useMemo(() => getBeforeId(layerId), [layerId, getBeforeId]);
  const {
    publicationStore: { sortedLayers },
  } = useContext(StoresContext);

  const { visible } = useMemo(() => {
    const useSortedLayers = getFlatSortedLayers(sortedLayers);
    return useSortedLayers.find((layer) => layer.id === createLayerId(wmsLayer.id)) || { visible: true };
  }, [sortedLayers, wmsLayer.id]);

  if (!visible) return null;

  return (
    <Source
      type="raster"
      id={wmsLayer.id}
      key={wmsLayer.id}
      tiles={[
        `${API_URL}geo-core/transferlayers/fetchWmsMap/${wmsLayer.id}?url=${encodeURIComponent(
          `${wmsLayer.url}?SERVICE=WMS&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=TRUE&STYLES=&VERSION=1.3.0&LAYERS=${wmsLayer.featureTypes.map(
            (feature) => feature.layerType.Name,
          )}&WIDTH=256&HEIGHT=256&SRS=EPSG:3857&CRS=EPSG:3857&BBOX=`,
        )}{bbox-epsg-3857}`,
      ]}
      tileSize={256}
    >
      <Layer
        {...({
          id: layerId,
          key: layerId,
          type: "raster",
          source: wmsLayer.id,
          paint: {
            "raster-opacity": visible ? 1 : 0,
          },
          beforeId: beforeId,
        } as LayerProps)}
      />
    </Source>
  );
});

export default withStyles(useMapStyles)(WmsLayerView);
