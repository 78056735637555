import {
  PublicationListObjectType,
  SearchProperty,
  MapLayerOrderItem,
  EditType,
  WfsTransferLayerListObject,
  WmsTransferLayerListObject,
  BaseLayerListObjectType,
  LayerGroupStatus,
  isLayerGroupGeoJsonActive,
  DefaultVisibilityType,
  PublicationShareConfig,
  MapGroupItem,
} from "@orbit/geo-core-shared";
import { makeAutoObservable, toJS } from "mobx";
import { createPublication, fetchPublication, fetchPublications, fetchPublicPublication, updatePublication } from "services/geo-core";

export default class PublicationStore {
  lastUsagePublic: boolean;
  publications: PublicationListObjectType[] = [];
  activePublication: PublicationListObjectType | null;
  _activeSearchFieldIndex: string = "";
  _deepObserverDisposer;

  constructor() {
    makeAutoObservable(this);
  }

  clearActivePublication = () => {
    this.activePublication = null;
  };

  setActivePublication = (publication) => {
    this.activePublication = publication;
    if (publication.searchFields) {
      this.activeSearchFieldIndex = this.activeSearchFields?.sort((a, b) => a.name.localeCompare(b.name))?.[0]?.index || Object.keys(publication.searchFields)[0];
    }
  };

  // PRIVATE VIEWER FUNCTION
  // MOET INGELOGD ZIJN
  fetchActivePublication = async (id: string) => {
    this.lastUsagePublic = false;
    this.clearActivePublication();
    const { data } = await fetchPublication(id);
    this.setActivePublication(data);
  };

  // PUBLIC VIEWER FUNCTION
  // MOET NIET INGELOGD ZIJN
  fetchActivePublicPublication = async (id: string) => {
    this.lastUsagePublic = true;
    this.clearActivePublication();
    const publication = await fetchPublicPublication(id);
    this.activePublication = publication;
  };

  loadPublications = async () => {
    this.publications = [];
    const publications: PublicationListObjectType[] = await fetchPublications();
    this.publications = publications;
  };

  createPublication = async (naam: string) => {
    const newPublication = await createPublication(naam);
    this.publications.push(newPublication);
    return newPublication.id;
  };

  updatePublication = async (
    id: string,
    type: EditType,
    data: string | string[] | { [key: string]: SearchProperty[] | object } | DefaultVisibilityType | PublicationShareConfig,
  ) => {
    const newPublication = await updatePublication(id, type, data);
    this.setActivePublication(newPublication);
  };

  setSearchPropertyItemPrimary = (itemkey) => {
    if (!this.activeSearchField) return;

    this.activeSearchField.items.forEach((item) => {
      item.primary = false;
    });
    const updatedItem = this.activeSearchField.items.find((item) => item.key === itemkey);
    if (updatedItem) updatedItem.primary = true;

    this.saveSearchFields();
  };

  toggleSearchPropertyItem = (newItem: SearchProperty, key) => {
    if (!this.activeSearchField) {
      return;
    }
    const updatedItem = this.activeSearchField.items.find((item) => item.key === newItem.key);
    if (updatedItem) {
      updatedItem[key] = !updatedItem[key];
    }
    this.saveSearchFields();
  };

  updateSearchPropertyItem = (newItem: SearchProperty, key, value) => {
    if (!this.activeSearchField) {
      return;
    }
    const updatedItem = this.activeSearchField.items.find((item) => item.key === newItem.key);
    if (updatedItem) {
      updatedItem[key] = value;
    }
    this.saveSearchFields();
  };

  saveSearchFields() {
    if (this.activePublication && this.activeSearchFields) {
      updatePublication(this.activePublication.id, EditType.Search, this.activePublication.searchFields);
    }
  }

  /**
   * will take the current search field index
   * and re-order the current index to the desitation index
   * all items after the destnation index will also get a new index
   *
   * @param currentIndex
   * @param destinationIndex
   * @returns
   */
  reorderSearchPropertyItem = (currentIndex: number, destinationIndex: number, type: string) => {
    if (!this.activeSearchField) {
      return;
    }
    let temp = [...this.activeSearchField.items];
    // get reference to dragged item
    const movedItem = temp[currentIndex];
    // remove item from current list
    temp.splice(currentIndex, 1);
    // move item to dropped index
    temp.splice(destinationIndex, 0, movedItem);
    // change index of object to index in array
    temp = temp.map((item, index) => ({ ...item, index: index }));
    // override old items with temp items
    this.activeSearchField.items = temp;
    this.saveSearchFields();
  };

  /**
   *
   * @param index -> elasticsearchIndex
   * @param enable
   */
  toggleAllSearchFieldsForIndex = async (index: string, enable: boolean) => {
    this.activePublication?.searchFields[index].items.map((item) => {
      item.search = enable;
    });

    this.saveSearchFields();
  };

  toggleAllResultFieldsForIndex = async (index: string, enable: boolean) => {
    this.activePublication?.searchFields[index].items.map((item) => {
      item.result = enable;
    });
    this.saveSearchFields();
  };

  setOrder = (items: MapLayerOrderItem[]) => {
    if (this?.activePublication?.sortedLayers) {
      this.activePublication.sortedLayers = items;
      this.saveLayerOrder();
    }
  };

  saveLayerOrder = async () => {
    if (this?.activePublication?.sortedLayers) {
      const updatedPublication = await updatePublication(this.activePublication.id, EditType.MAP_LAYER_ORDER, this.activePublication.sortedLayers);
      this.activePublication = updatedPublication;
    }
  };

  setSortedBaseLayers = (items: BaseLayerListObjectType[]) => {
    if (this?.activePublication?.sortedBaseLayers) {
      this.activePublication.sortedBaseLayers = items.map((i) => i.id);
      this.saveBaseLayerOrder();
    }
  };

  saveBaseLayerOrder = () => {
    if (this?.activePublication?.sortedBaseLayers) {
      updatePublication(this.activePublication.id, EditType.MAP_BASE_LAYER_ORDER, this.activePublication.sortedBaseLayers);
    }
  };

  get wfsLayers(): WfsTransferLayerListObject[] {
    return (this.activePublication?.transferLayer?.filter?.((layer) => layer.type === "wfs") as WfsTransferLayerListObject[]) || [];
  }

  get wmsLayers(): WmsTransferLayerListObject[] {
    return (this.activePublication?.transferLayer?.filter?.((layer) => layer.type === "wms") as WmsTransferLayerListObject[]) || [];
  }

  set activeSearchFieldIndex(index: string) {
    this._activeSearchFieldIndex = index;
  }

  get activeSearchFieldIndex() {
    return this._activeSearchFieldIndex;
  }

  get activeSearchField() {
    if (!this.activeSearchFieldIndex || !this?.activePublication?.searchFields) {
      return null;
    }
    return this?.activePublication?.searchFields[this.activeSearchFieldIndex];
  }

  /**
   * checks if every item in the current activesearchField
   */
  get allResultFieldsSelected() {
    if (!this.activeSearchField) {
      return false;
    }
    return this.activeSearchField.items.every((item) => item.result);
  }

  get allSearchFieldsSelected() {
    if (!this.activeSearchField) {
      return false;
    }
    return this.activeSearchField.items.every((item) => item.search);
  }

  /**
   * returns all searchable fields
   * also tries to map the es index to the displayname of the layers of the layergroup
   * if no displayname is found no name found is returned
   */
  get activeSearchFields(): { index: string; name: string }[] {
    if (!this.activePublication?.searchFields) {
      return [];
    }
    return Object.keys(this.activePublication.searchFields).map((item) => ({
      index: item,
      name:
        this.activePublication?.layerGroup?.layers.find((layer) => item.includes(layer.layerName))?.displayName ??
        this.activePublication?.publicationEnrichments.filter((enrichment) => enrichment.id === item)[0]?.name ??
        this.getWfsLayerNameBySearchFieldId(item) ??
        "No name found",
    }));
  }

  /**
   * returns all keys from the searchfields
   * with an array of fields that are visible in the result
   * will be used in the map to filter the popup items show
   */
  get searchFilter(): { [key: string]: string[] } {
    if (!this.activePublication?.searchFields) {
      return {};
    }
    return Object.entries(this.activePublication.searchFields).reduce((accum, [key, item]) => {
      // remove uuid from the key
      // because the publication doesn't have the uuid of the layergroup
      key = key.replace(/-\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g, "");
      if (!accum[key]) {
        accum[key] = [];
      }
      accum[key] = [
        ...item.items
          .slice()
          .sort((a, b) => a.index - b.index)
          .filter((filterItem) => filterItem.result)
          .map((item) => item.key),
      ];
      return accum;
    }, {});
  }

  /**
   * returns all searchable fields with type of date
   * used in the searchbar to generate a list of date selectors
   */
  get searchDateFields(): SearchProperty[][] {
    if (!this.activePublication?.searchFields) {
      return [];
    }
    return Object.entries(this.activePublication.searchFields).map(([key, value]) =>
      value.items.reduce((accum: SearchProperty[], item) => {
        if (item.item.type === "date" && item.search) {
          accum.push(item);
        }
        return accum;
      }, []),
    );
  }

  get sortedLayers(): MapLayerOrderItem[] | MapGroupItem[] {
    if (this?.activePublication?.sortedLayers?.length) {
      return this.activePublication.sortedLayers;
    }

    return [];
  }

  get sortedBaseLayers(): BaseLayerListObjectType[] {
    if (this?.activePublication?.baseLayers?.length) {
      const currentOrderIds = this.activePublication.sortedBaseLayers?.length ? this.activePublication.sortedBaseLayers : this.activePublication.baseLayers.map((b) => b.id);
      const currentOrderLayers = currentOrderIds
        .map((orderId) => this.activePublication?.baseLayers?.find((l) => l.id === orderId))
        .filter((l) => !!l) as BaseLayerListObjectType[];

      // set new layers as last
      this?.activePublication?.baseLayers.forEach((baseLayer) => {
        const notFound = currentOrderLayers.every((ol) => ol.id !== baseLayer.id);
        if (notFound) currentOrderLayers.push(baseLayer);
      });

      return currentOrderLayers;
    }
    return [];
  }

  getPublicationActiveStyle() {
    if (this?.activePublication?.layerGroup?.status === LayerGroupStatus.FINISHED) {
      return this.activePublication.style;
    } else if (isLayerGroupGeoJsonActive(this?.activePublication?.layerGroup?.status)) {
      return this.activePublication?.geoJsonStyle || this.activePublication?.style || null;
    }
    return this?.activePublication?.style;
  }

  updateLayerGroup(layerGroupId, updateProperties) {
    // Update properties on activePublication (when current)
    if (this?.activePublication?.layerGroup && this?.activePublication?.layerGroup?.id === layerGroupId) {
      Object.entries(updateProperties || {}).forEach(([key, value]) => {
        this.activePublication.layerGroup[key] = value;
      });

      // Process after geosjon is finished or process is fully finished (so reload data)
      if (updateProperties?.status === LayerGroupStatus.SYNCING_ELASTICSEARCH || updateProperties?.status === LayerGroupStatus.FINISHED) {
        this.lastUsagePublic ? this.fetchActivePublicPublication(this.activePublication.id) : this.fetchActivePublication(this.activePublication.id);
      }
    }
  }

  getWfsLayerNameBySearchFieldId(searchFieldId: string): string | undefined {
    let layerName;
    this.activePublication?.transferLayer
      ?.filter((layer) => layer.type === "wfs")
      .forEach((transferLayer) => {
        transferLayer.featureTypes.forEach((layerFeature) => {
          if (`${transferLayer.id}-${layerFeature.featureType.Name}` === searchFieldId) {
            layerName = `${transferLayer.name} | ${layerFeature.featureType.Title}`;
          }
        });
      });
    return layerName;
  }
}
